(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    $(document).on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init(function(){
        $(document).find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn, .part_ui_link").nl_lib_ripple();

    if ($(".lib--rellax").length && $window.width() > 960) {
        $.getScript($cdnjs.rellax).done(function() {
            new Rellax('.lib--rellax', {
                center: true
            });
        });
    }

    var picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !$("html").hasClass("ie"))
    {
        $.getScript($cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        $html.addClass("ie11");
    }
    
    // AJAX FORM+
    $(document).on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        var $frm = $(this);
        var formData = new FormData($(this)[0]);
        $.ajax({
            method: $frm.attr('method'),
            url: $frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false
        });
    });

    // ALAX LINKS
    $(document).on('click', '[href].ajax', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        });
    });

    // gallerie
    $(document).on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        }).done(function(){
            $('[data-changegallery]').removeClass('mod--active');
            $el.addClass('mod--active');
            gallery($(".comp_gallery"));            
        });
    });

    // blog
    $(document).on('click', '[data-changeblog]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {ajax: 1}
        }).done(function(){
            $('[data-changeblog]').removeClass('mod--active');
            $el.addClass('mod--active');
        });
    });
    
    // blog next
    $(document).on('click', '[data-blognext]', function (e) {
        e.preventDefault();
        var $el = $(this);
        $.ajax({
            url: $el.attr('href'),
            data: {'do': 'nextblog', 'idCategory': $el.data('idcategory'), 'page': $el.data('page'), ajax: 1}
        });
    });

    var antispam = function () {
        if ($('#snippet-contactform').length) {
            setTimeout(function () {
                $('#snippet-contactform').append('<input type="hidden" class="text" name="antispam" value="antispam" />');
            }, 3000);
        }
    };
    
    $(document).ready(function () {
        antispam();
    });
    
    /* GLOBAL AJAX EVENT HANDLER */
    $.ajaxSetup({
        dataType: "json",
        success: function (payload) {
            if (payload) {
                // přesměrování "this" přesměruje na aktuální stránku
                if (payload.redirect) {
                    if (payload.redirect == 'this') {
                        location.href = window.location.href.split("#")[0];
                    } else {
                        location.href = payload.redirect;
                    }
                }

                // výměna snippetů v šabloně - každý snippet musí mít unikátní ID
                if (payload.snippets) {
                    for (var i in payload.snippets) {
                        $("#" + i).replaceWith(payload.snippets[i]);
                    }
                }

                // výměna adresy v adresním řádku
                if (payload.replaceState) {
                    window.history.replaceState(null, payload.replaceState.title || null, payload.replaceState.url);

                    if (payload.replaceState.title) {
                        document.title = payload.replaceState.title;
                    }

                    if (typeof window.ga === "function") {
                        ga('send', 'pageview', window.location.pathname + window.location.search);
                    }
                }
                antispam();
            }
        }
    });    
})(jQuery);