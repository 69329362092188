(function($){
    const fn = $(".comp_slider_wellness");

    if (fn.length) {
        const slider = fn.find("[data-slider]");
        const slider_nav = fn.find("[data-slider-nav]");

        cssLoaded(function(){
            if (slider.children().length > 2) {
                let rows = 2;
                if ($window.width() < 768) {
                    rows = 1;
                }
                slider.slick({
                    infinite: true,
                    speed: 500,
                    arrows: false,
                    dots: false,
                    adaptiveHeight: false,
                    fade: true,
                    rows: rows,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                fade: false,
                            }
                        }
                    ]
                });

                slider_nav.on("click", function() {
                    if ($(this).data("slider-nav") === "prev") {
                        slider.slick("slickPrev");
                    } else {
                        slider.slick("slickNext");
                    }
                });

            }
        });
    }
})(jQuery);