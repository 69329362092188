var $doc = $(document),
    $window = $(window),
    $html = $("html"),
    $body = $("body"),
    $lang = $html.attr('lang');

if ('serviceWorker' in navigator && !$html.hasClass("no-sw")) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/sw.js').catch(function(e) {
            console.error('Error during service worker registration:', e);
        });
    });
}

(function($) {
    cssLoaded(function(){
        $body.removeClass("mod--preload");
        setTimeout(function () {
            $body.addClass("mod--loaded");
        }, 300);
    });
})(jQuery); 