(function ($) {
    let $fn = $(".comp_site");

    if ($fn.length) {
        let nav = $fn.find(".wrp_comp_nav");

        if (nav.length) {
            if(nav.find("a:not([href])").length) {
                nav[0].scrollLeft = nav.find("a:not([href])").closest("li")[0].offsetLeft - 50;
            }
        }

        // nav.on("click","a[href]",function (e) {
        //     let el = $(this);
        //     nav.animate({
        //         scrollLeft: el.closest("li")[0].offsetLeft - 50
        //     })
        // })
    }
})(jQuery);
