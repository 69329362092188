function bodyLoaded(callback) {
    if (!$body.hasClass("mod--loaded")) {
        var wait = setInterval(function(){
            if ($body.hasClass("mod--loaded")) {
                clearInterval(wait);
                callback();
            }
        },0);
    } else {
        callback();
    }
}