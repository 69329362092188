(function($){
    const fn = $(".comp_slider_conference");

    if (fn.length) {
        const slider_images = fn.find("[data-slider-images]");
        const slider_nav = fn.find("[data-slider-nav]");
        const slider_counter = fn.find("[data-slider-counter]");

        cssLoaded(function(){
            if (slider_images.children().length > 1) {
                slider_images.slick({
                    infinite: true,
                    speed: 500,
                    arrows: false,
                    dots: false,
                    adaptiveHeight: false
                });

                slider_images.on('afterChange', function(data,item){
                    slider_counter.text(`${item.currentSlide+1} / ${item.slideCount}`)
                });

                slider_nav.on("click", function() {
                    if ($(this).data("slider-nav") === "prev") {
                        slider_images.slick("slickPrev");
                    } else {
                        slider_images.slick("slickNext");
                    }
                });

            }
        });
    }
})(jQuery);