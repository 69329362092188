(function($){
    const $fn = $(".comp_visual"),
        $slider = $fn.find("[data-visual-slider]"),
        $slider_nav = $fn.find("[data-slider-nav]"),
        $slider_caption = $fn.find("[data-visual-caption]"),
        $slider_autoplay = $fn.find('[data-visual-autoplay]').data("visual-autoplay");

    if ($fn.length) {
        cssLoaded(function(){
            let $autoplay = true;
            if ($slider_autoplay === "0" || $slider_autoplay === "") {
                $autoplay = false;
            }

            let $slider_for = $slider,
                $slider_caption_for = $slider_caption;

            if ($slider_caption.children().length < 1 || $slider.children().length < 1) {
                $slider_caption_for = null;
                $slider_for = null;
            }

            if ($slider.children().length > 1) {
                $slider.slick({
                    infinite: true,
                    speed: 500,
                    fade: true,
                    cssEase: 'linear',
                    arrows: false,
                    pauseOnHover: false,
                    asNavFor: $slider_caption_for,
                    dots: false
                });
                $slider.on('beforeChange', function(){
                    $(this).find(".slick-active").next().find("img").addClass("lazyload");
                });
                $slider.on('afterChange', function(data,item){
                    $(this).find(".slick-active").find("img").addClass("lazyload");
                    if ($slider_nav.length) {
                        $slider_nav.find(".mod--active").removeClass("mod--active");
                        $slider_nav.find(`[data-slider-nav-toggle="${item.currentSlide}"]`).addClass("mod--active");
                    }
                });

                $slider_nav.on("click", "[data-slider-nav-toggle]", function(){
                    let $el = $(this);
                    $slider_nav.find(".mod--active").removeClass("mod--active");
                    $el.addClass("mod--active");
                    $slider.slick("slickGoTo",$el.data("slider-nav-toggle"));
                });
            }

            if ($slider_caption.children().length > 1) {
                $slider_caption.slick({
                    infinite: true,
                    speed: 500,
                    fade: true,
                    cssEase: 'linear',
                    arrows: false,
                    dots: false,
                    autoplay: $autoplay,
                    autoplaySpeed: $slider_autoplay,
                    pauseOnHover: true,
                    asNavFor: $slider_for,
                    adaptiveHeight: true
                });
                $slider_nav.on({
                    mouseenter: function () {
                        $slider_caption.slick("slickPause");
                    },
                    mouseleave: function () {
                        $slider_caption.slick("slickPlay");
                    }
                },"[data-slider-nav-toggle]");
            }
        });
    }
})(jQuery);