(function($){
    const $fn = $(".part_form_booking");

    if ($fn.length) {
            let $checkin = $('[data-form-datepicker="arrival"]');
            let $checkout = $('[data-form-datepicker="departure"]');
            let $number = $('[data-form-number]');

        $.getScript("https://cdnjs.cloudflare.com/ajax/libs/air-datepicker/2.2.3/js/i18n/datepicker." + $lang + ".min.js").done(function () {
            let $datepicker_in = $checkin.find("[data-arrival]").datepicker({
                language: $lang,
                position: "top center",
                minDate: new Date(),
                autoClose: true,
                dateFormat: 'yyyy-mm-dd',
                onSelect: function (fd, d) {
                    let $month = $.fn.datepicker.language[$lang]['months'][d.getMonth()];
                    $checkin.find("[data-day]").text(d.getDate());
                    $checkin.find("[data-month]").text($month);
                    d.setDate(d.getDate()+1);
                    $checkout.find("[data-departure]").datepicker().data('datepicker').selectDate(d);
                    $checkout.find("[data-departure]").datepicker().data('datepicker').update({
                        minDate: d
                    });
                }
            }).data('datepicker');

            let $datepicker_out = $checkout.find("[data-departure]").datepicker({
                language: $lang,
                position: "top center",
                minDate: new Date(),
                autoClose: true,
                dateFormat: 'yyyy-mm-dd',
                onSelect: function(fd, d) {
                    let $month = $.fn.datepicker.language[$lang]['months'][d.getMonth()];
                    $checkout.find("[data-day]").text(d.getDate());
                    $checkout.find("[data-month]").text($month);
                }
            }).data('datepicker');

            $checkin.on("click", function(){
                $datepicker_in.show();
            });

            $checkout.on("click", function(){
                $datepicker_out.show();
            });

            $number.each(function(){
                let $elm = $(this);
                let $num = 12;
                let $number_enable = [];

                for (let i=0; i <= $num; i++) {
                    $number_enable.push(i);
                }

                let $number_picker = $elm.find("[data-select-input]").datepicker({
                    language: $lang,
                    position: "top center",
                    autoClose: true,
                    classes: "mod--number-picker",
                    dateFormat: "d",
                    clearButton: true,
                    showOtherYears: false,
                    showOtherMonths: false,
                    onSelect: function(fd, d) {
                        if (!fd) {
                            $elm.find("[data-day]").text(0);
                        } else {
                            $elm.find("[data-day]").text(d.getDate());
                        }
                    },
                    onRenderCell: function (date, cellType) {
                        if (cellType === 'day') {
                            let day = date.getDate();
                            if ($number_enable.indexOf(day) !== -1) {
                                return {
                                    classes: "-enabled-"
                                }
                            }
                        }
                    }
                }).data('datepicker');

                $elm.on("click",function(){
                    $number_picker.show();
                });
            });
        });
    }
})(jQuery);