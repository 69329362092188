(function($){
    const fn = $(".comp_rooms_and_offers");
    const fn_rooms = fn.find(".wrp_comp_rooms");
    const fn_offers = fn.find(".wrp_comp_offers");
    cssLoaded(function(){
        if (fn_rooms.length) {
            const slider = fn_rooms.find("[data-slider]");
            const slider_images = fn_rooms.find("[data-slider-images]");
            const slider_nav = fn_rooms.find("[data-slider-nav]");
            const slider_counter = fn_rooms.find("[data-slider-counter]");

            if (slider.children().length > 1) {
                slider.slick({
                    infinite: true,
                    speed: 500,
                    fade: true,
                    cssEase: 'linear',
                    arrows: false,
                    dots: false,
                    asNavFor: slider_images,
                    adaptiveHeight: false,
                    responsive: [
                        {
                            breakpoint: 960,
                            settings: {
                                adaptiveHeight: true
                            }
                        }
                    ]
                });

                slider_images.slick({
                    infinite: true,
                    speed: 500,
                    arrows: false,
                    dots: false,
                    asNavFor: slider,
                    adaptiveHeight: false
                });

                slider.on('afterChange', function(data,item){
                    slider_counter.text(`${item.currentSlide+1} / ${item.slideCount}`)
                });

                slider_nav.on("click", function() {
                    if ($(this).data("slider-nav") === "prev") {
                        slider.slick("slickPrev");
                    } else {
                        slider.slick("slickNext");
                    }
                });

            }
        }
        if (fn_offers.length) {
            const slider = fn_offers.find("[data-slider]");
            const slider_nav = fn_offers.find("[data-slider-nav]");
            const slider_counter = fn_offers.find("[data-slider-counter]");

            if (slider.children().length > 2) {
                slider.slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    centerMode: true,
                    speed: 1000,
                    autoplay: false,
                    pauseOnHover: true,
                    arrows: false,
                    prevArrow: '<button type="button" class="slick-prev icon--chevron-left"></button>',
                    nextArrow: '<button type="button" class="slick-next icon--chevron-right"></button>',
                    dots: false,
                    responsive: [
                        {
                            breakpoint: 960,
                            settings: {
                                slidesToShow: 1,
                                centerMode: false
                            }
                        }
                    ]
                });

                slider.on('afterChange', function(data,item){
                    slider_counter.text(`${item.currentSlide+1} / ${item.slideCount}`)
                });

                slider_nav.on("click", function() {
                    if ($(this).data("slider-nav") === "prev") {
                        slider.slick("slickPrev");
                    } else {
                        slider.slick("slickNext");
                    }
                });
            }
        }
    });
})(jQuery);