function gallery($comp) {
    $.getCSS($cdnjs.lightgallery_css);
    $.getScript($cdnjs.lightgallery).done(function(){
        $comp.find("[data-gallery-row]").lightGallery({
            thumbnail: true,
            selector: ".part_item_gallery",
            exThumbImage: 'data-exthumbimage',
            fullScreen: false,
            zoom: true,
            actualSize: false,
            hash: true,
            download: true,
            autoplay: false,
            autoplayControls: true
        });
    });
};